<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-md-6">
            <div class="mb-5 mb-md-0">
              <span class="d-block small font-weight-bold text-cap mb-2">our solutions</span>
              <h1>API Community Development</h1>
            </div>
          </div>

          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-community.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Overveiw Section ========== -->
    <!--
    <div class="container space-2 space-lg-3">
      <div class="row justify-content-lg-between">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <span class="d-block small font-weight-bold text-cap mb-2">Overview</span>
          <h2 class="mb-3">Leading API Community in Hong Kong.</h2>
        </div>
        <div class="col-lg-6">
          <div class="media mb-3">
            <span class="mr-3">—</span>
            <div class="media-body">
              <h2 class="h4">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste, aliquid.</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus corrupti doloremque possimus quo quod, quia a tenetur deleniti accusamus tempore?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <!-- <div class="container space-2 space-lg-3">
      <div class="row align-items-lg-center">
        <div class="col-lg-7">
          <div class="mb-5 mb-lg-0">
            <span class="d-block small font-weight-bold text-cap mb-2">Overview</span>
            <h2 class="mb-3">Lorem ipsum dolor sit amet.</h2>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                <i class="fas fa-check" />
              </span>
              <div class="media-body">
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore, natus!</p>
              </div>
            </div>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                <i class="fas fa-check" />
              </span>
              <div class="media-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere repellendus totam dolorem, ea quidem velit a.</p>
              </div>
            </div>
            <div class="media mb-3">
              <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                <i class="fas fa-check" />
              </span>
              <div class="media-body">
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate debitis reiciendis reprehenderit.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5">
          <div class="w-80 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/career-hero-2.svg">
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Overview Section ========== -->

    <!-- ========== Events Section ========== -->
    <div class="position-relative">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Community Events</span>
          <h2>Check Out Our Latest Events</h2>
        </div>

        <div class="row w-lg-80 mx-auto">
          <div id="ga-solution-apicommunity-apidays-card" class="col-md-6 mb-4 mb-md-0">
            <!-- <a class="card h-100 text-center transition-3d-hover" href="../../events/apidays-live-hongkong-2021"> -->
            <a class="card h-100 text-center transition-3d-hover" href="https://www.apidays.hk/" target="_blank">
              <div class="card-body pt-6">
                <div class="max-w-10rem mx-auto my-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-7.svg">
                </div>
                <h3>Apidays Live Hong Kong</h3>
                <p class="text-body">beNovelty is the lead Hong Kong organiser of the world leading API community conference - APIDays </p>
              </div>
              <div class="card-footer border-0 pt-0 pb-6">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
            <!-- </a> -->
          </div>

          <div id="ga-solution-apicommunity-webinars-card" class="col-md-6 mb-4 mb-md-0">
            <!-- <a class="card h-100 text-center transition-3d-hover" href="../../events"> -->
            <router-link class="card h-100 text-center transition-3d-hover" :to="{ path: '/events' }">
              <div class="card-body pt-6">
                <div class="max-w-10rem mx-auto my-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-18.svg">
                </div>
                <h3>Webinars</h3>
                <p class="text-body">beNovelty's webinar series in API Economy</p>
              </div>
              <div class="card-footer border-0 pt-0 pb-6">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </router-link>
            <!-- </a> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Events Section ========== -->

    <!-- ========== Gallery Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Gallery</span>
          <h2>The Best Moments of beNovelty Events</h2>
        </div>
        <div id="fancyboxGallery1" class="row">
          <div v-for="item in gallery_list" :key="item.image_url" class="col-12 px-2 transition-3d-hover" :class="item.class">
            <a
              class="js-fancybox media-viewer"
              href="javascript:;"
              data-hs-fancybox-options="{
                        &quot;selector&quot;: &quot;#fancyboxGallery1 .js-fancybox&quot;,
                        &quot;speed&quot;: 700
                    }"
              :data-src="require('@/assets/img/events/community-gallery/' + item.image_url)"
              :data-caption="item.caption"
            >
              <div class="img-fluid rounded h-250rem bg-img-hero shadow-soft" :style="{'background-image': 'url(' + require('@/assets/img/events/community-gallery/' + item.image_url) + ')'}" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Gallery Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-lg-3">
      <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
    </div>
    <!-- ========== End CTA Section ==========  -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 space-lg-3 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">More by beNovelty</span>
        <h2>Want to Know More About API Economy?</h2>
      </div>
      <div class="position-relative">
        <div class="container">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-lg-none&quot;,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 5000,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-4.svg">
                  <h4>API Consultancy</h4>
                  <p>Read more to find out what makes beNovelty different in API consultancy.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-consultancy-button" href="/solutions/api-consultancy"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-consultancy-button" :to="{ path: '/solutions/api-consultancy' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-28.svg">
                  <h4>API Product</h4>
                  <p>Find out the 3 simple steps for building API-as-a-Products.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-product-button" href="/solutions/api-product"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-product-button" :to="{ path: '/solutions/api-product' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-3.svg">
                  <h4>API Management</h4>
                  <p>Learn how to manage, secure, mediate, analyze complex enterprise API programme with API Management.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-management-button" href="/solutions/api-management"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-management-button" :to="{ path: '/solutions/api-management' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <!-- <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-69.svg">
                  <h4>API Community</h4>
                  <p>Discover more on our latest API community events.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <a id="ga-solutions-more-by-benovelty-api-community-button" href="/solutions/api-community">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></a>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.fancybox.js'
import '../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import 'bootstrap'
import { mapGetters } from 'vuex'
export default {
  name: 'ApiCommunity',
  components: {
    ContactUsBar
  },
  data() {
    return {
      gallery_list: [
        {
          image_url: 'apiecobooster-demoday-1.jpg',
          class: 'col-md-7 mb-3',
          data_aos_delay: 100,
          caption: 'API EcoBooster Demo Day 2020'
        },
        {
          image_url: 'apiecobooster-demoday-2.jpg',
          class: 'col-md-5 mb-3',
          data_aos_delay: 0,
          caption: 'API EcoBooster Demo Day 2020'
        },
        {
          image_url: 'apidays-1.jpg',
          class: 'col-md-3 mb-3',
          data_aos_delay: 0,
          caption: 'Apidays Live Hong Kong 2020'
        },
        {
          image_url: 'apidays-2.png',
          class: 'col-md-5 mb-3',
          data_aos_delay: 100,
          caption: 'Apidays Live Hong Kong 2020'
        },
        {
          image_url: 'apidays-3.png',
          class: 'col-md-4',
          data_aos_delay: 200,
          caption: 'Apidays Live Hong Kong 2020'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'API Community | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/api-community' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'API Community | Solutions | beNovelty' },
        { property: 'og:description', content: 'API business enabler & community builder for local API Community. Local organizer of APIDays Hong Kong.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/api-community' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>
